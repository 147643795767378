export const bibleBooks = [
  // Old Testament
  { index: 1, name: "Genesis", hangeul: "창", hangeul_long: "창세기" },
  { index: 2, name: "Exodus", hangeul: "출", hangeul_long: "출애굽기" },
  { index: 3, name: "Leviticus", hangeul: "레", hangeul_long: "레위기" },
  { index: 4, name: "Numbers", hangeul: "민", hangeul_long: "민수기" },
  { index: 5, name: "deuteronomy", hangeul: "신", hangeul_long: "신명기" },
  { index: 6, name: "joshua", hangeul: "수", hangeul_long: "여호수아" },
  { index: 7, name: "judges", hangeul: "삿", hangeul_long: "신명기" },
  { index: 8, name: "ruth", hangeul: "룻", hangeul_long: "룻기" },
  { index: 9, name: "1samuel", hangeul: "삼상", hangeul_long: "사무엘상" },
  { index: 10, name: "2samuel", hangeul: "삼하", hangeul_long: "사무엘하" },
  { index: 11, name: "1kings", hangeul: "왕상", hangeul_long: "열왕기상" },
  { index: 12, name: "2kings", hangeul: "왕하", hangeul_long: "열왕기하" },
  { index: 13, name: "1chronicles", hangeul: "대상", hangeul_long: "역대상" },
  { index: 14, name: "2chronicles", hangeul: "대하", hangeul_long: "역대하" },
  { index: 15, name: "ezra", hangeul: "스", hangeul_long: "에스라", long_two: "에즈라" },
  { index: 16, name: "nehemiah", hangeul: "느", hangeul_long: "느헤미야" },
  { index: 17, name: "esther", hangeul: "에", hangeul_long: "에스더" },
  { index: 18, name: "job", hangeul: "욥", hangeul_long: "욥기" },
  { index: 19, name: "Psalms", hangeul: "시", hangeul_long: "시편" },
  { index: 20, name: "Proverbs", hangeul: "잠", hangeul_long: "잠언" },
  { index: 21, name: "Ecclesiastes", hangeul: "전", hangeul_long: "전도서" },
  { index: 22, name: "Song of Solomon", hangeul: "아", hangeul_long: "아가" },
  { index: 23, name: "Isaiah", hangeul: "사", hangeul_long: "이사야" },
  { index: 24, name: "Jeremiah", hangeul: "렘", hangeul_long: "예레미야" },
  { index: 25, name: "Lamentations", hangeul: "애", hangeul_long: "예레미야애가" },
  { index: 26, name: "ezekiel", hangeul: "겔", hangeul_long: "에스겔" },
  { index: 27, name: "daniel", hangeul: "단", hangeul_long: "다니엘" },
  { index: 28, name: "hosea", hangeul: "호", hangeul_long: "호세아" },
  { index: 29, name: "joel", hangeul: "욜", hangeul_long: "요엘" },
  { index: 30, name: "amos", hangeul: "암", hangeul_long: "아모스" },
  { index: 31, name: "Obadiah", hangeul: "옵", hangeul_long: "오바댜" },
  { index: 32, name: "jonah", hangeul: "욘", hangeul_long: "요나" },
  { index: 33, name: "micah", hangeul: "미", hangeul_long: "미가" },
  { index: 34, name: "nahum", hangeul: "나", hangeul_long: "나훔" },
  { index: 35, name: "habakkuk", hangeul: "합", hangeul_long: "하박국" },
  { index: 36, name: "Zephaniah", hangeul: "습", hangeul_long: "스바냐" },
  { index: 37, name: "haggai", hangeul: "학", hangeul_long: "학개" },
  { index: 38, name: "Zechariah", hangeul: "슥", hangeul_long: "스가랴" },
  { index: 39, name: "malachi", hangeul: "말", hangeul_long: "말라기" },
  // New Testament
  { index: 40, name: "matthew", hangeul: "마", hangeul_long: "마태복음" },
  { index: 41, name: "mark", hangeul: "막", hangeul_long: "마가복음" },
  { index: 42, name: "luke", hangeul: "눅", hangeul_long: "누가복음" },
  { index: 43, name: "john", hangeul: "요", hangeul_long: "요한복음" },
  { index: 44, name: "acts", hangeul: "행", hangeul_long: "사도행전" },
  { index: 45, name: "Romans", hangeul: "롬", hangeul_long: "로마서" },
  { index: 46, name: "1corinthians", hangeul: "고전", hangeul_long: "고린도전서" },
  { index: 47, name: "2corinthians", hangeul: "고후", hangeul_long: "고린도후서" },
  { index: 48, name: "galatians", hangeul: "갈", hangeul_long: "갈라디아서" },
  { index: 49, name: "ephesians", hangeul: "엡", hangeul_long: "에베소서" },
  { index: 50, name: "philippians", hangeul: "빌", hangeul_long: "빌립보서" },
  { index: 51, name: "colossians", hangeul: "골", hangeul_long: "골로새서" },
  { index: 52, name: "1Thessalonians", hangeul: "살전", hangeul_long: "데살로니가전서" },
  { index: 53, name: "2Thessalonians", hangeul: "살후", hangeul_long: "데살로니가후서" },
  { index: 54, name: "1Timothy", hangeul: "딤전", hangeul_long: "디모데전서" },
  { index: 55, name: "2Timothy", hangeul: "딤후", hangeul_long: "디모데후서" },
  { index: 56, name: "Titus", hangeul: "딛", hangeul_long: "디도서" },
  { index: 57, name: "philemon", hangeul: "몬", hangeul_long: "빌레몬서" },
  { index: 58, name: "hebrews", hangeul: "히", hangeul_long: "히브리서" },
  { index: 59, name: "james", hangeul: "약", hangeul_long: "야고보서" },
  { index: 60, name: "1peter", hangeul: "벧전", hangeul_long: "베드로전서" },
  { index: 61, name: "2peter", hangeul: "벧후", hangeul_long: "베드로후서" },
  { index: 62, name: "1john", hangeul: "요일", hangeul_long: "요한일서" },
  { index: 63, name: "2john", hangeul: "요이", hangeul_long: "요한2서" },
  { index: 64, name: "3john", hangeul: "요삼", hangeul_long: "요한3서" },
  { index: 65, name: "jude", hangeul: "유", hangeul_long: "유다서" },
  { index: 66, name: "revelation", hangeul: "계", hangeul_long: "요한계시록" },
];
