import "./home.scss";
import { useState } from "react";
import Loader from "react-loaders";
import axios from "axios";
import { bibleBooks } from "../conversions/conversions";
import { createRipple } from "../effects/ripple";

const VERSIONS_TO_LOAD = "rnksv,esv";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [stitched, setStitched] = useState([]);
  const [book, setBook] = useState("");
  const [chapter, setChapter] = useState("");
  const [verse, setVerse] = useState("");

  const loadBible = async (bookName) => {
    let result = null;

    try {
      const { data } = await axios.get(
        `https://newdreamchurch.com/api/bible/${VERSIONS_TO_LOAD}?book=${bookName}&chapter=${chapter}${verseCatcher(
          verse.trim()
        )}`
      );

      result = data;
    } catch (error) {
      setError("Something went wrong!");
      setStitched([]);
    }

    return result;
  };

  const fetchVerses = async (e) => {
    e.preventDefault();
    setError("");

    if (book.length !== 0 && chapter.length !== 0) {
      const bibleBook = translator(book);

      if (bibleBook) {
        setLoading(true);
        const response = await loadBible(bibleBook.index);
        const koreanResponse = response.data.rnksv;
        const englishResponse = response.data.esv;

        setStitched(weave(koreanResponse, englishResponse));
        setLoading(false);
      } else {
        setError("Given book name is invalid");
        setStitched([]);
      }
    } else return;
  };

  return (
    <div className="home_page">
      <h1 className="title">Qt Book Maker</h1>
      <form onSubmit={(e) => fetchVerses(e)}>
        <div>
          <span>Book</span>
          <input
            onChange={(e) => setBook(e.currentTarget.value)}
            type="text"
            placeholder="성경"
          />
        </div>
        <div>
          <span>Chapter</span>
          <input
            onChange={(e) => setChapter(e.currentTarget.value)}
            type="number"
            placeholder="장"
          />
        </div>
        <div>
          <span>Verses</span>
          <input
            maxLength={7}
            onChange={(e) => setVerse(e.currentTarget.value)}
            type="text"
            inputMode="numeric"
            placeholder="구절 eg. 1-3 (optional)"
          />
        </div>
        <button onClick={createRipple} type="submit">
          Search
        </button>
      </form>
      <div className="verses">
        {verse.length !== 0 ? (
          <h2>
            {capitalizeFirstLetter(book)} {chapter}:{verse}
          </h2>
        ) : (
          <h2>
            {capitalizeFirstLetter(book)} {chapter}
          </h2>
        )}

        {loading ? (
          <div className="loader_div">
            <Loader type="ball-pulse" />
          </div>
        ) : (
          stitched.map((v, i) => (
            <div className="verses" key={i}>
              <li style={{ marginBottom: !!v.id ? "16px" : 0 }}>
                {!v.id && v.paragraph ? v.paragraph : null}{" "}
                {v.sentence.replace(/(<([^>]+)>)/gi, "")}
              </li>
            </div>
          ))
        )}

        {error ? <p style={{ color: "red" }}>{error}</p> : null}
      </div>
    </div>
  );
};

// Helper functions ------------------------------------------------------------------
const splicer = (array, element, index) => {
  array.splice(index * 2, 0, element);
  return array;
};

const weave = (array1, array2) => {
  return array1.reduce(splicer, array2.slice());
};

const translator = (input) => {
  const englishNameIndex = bibleBooks.findIndex(
    (bibleBook) =>
      bibleBook.name.toLowerCase().replaceAll(" ", "") ===
      input.toLowerCase().replaceAll(" ", "")
  );
  const koreanNameIndex = bibleBooks.findIndex(
    (bibleBook) =>
      bibleBook.hangeul === input.replaceAll(" ", "") ||
      bibleBook.hangeul_long === input.replaceAll(" ", "") ||
      bibleBook.long_two === input.replaceAll(" ", "")
  );

  // when the input is English
  if (englishNameIndex !== -1) {
    return bibleBooks[englishNameIndex];
  }

  // when the input is Korean
  if (koreanNameIndex !== -1) {
    return bibleBooks[koreanNameIndex];
  }

  return null;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const verseCatcher = (verse) => {
  if (verse.length === 0) return "";
  if (verse.includes("-")) return "&verse=" + verse;
  return `&verse=${verse}-${verse}`;
};

export default Home;
